const okWithCookies = () => {
    setAdjustID();

    // Dismiss banner and save choice
    const cookieBanner = document.getElementById("cookie-banner");
    cookieBanner.classList.add("dismissed");
    localStorage.setItem("CookieClosed", true);
};

const checkStatus = (cookieBanner, acceptButton, manageButton) => {
    const urlParams = new URLSearchParams(window.location.search);
    const isExp = urlParams.get("exp");
    if (!localStorage.getItem("CookieClosed") && !isExp) {
        setTimeout(() => {
            cookieBanner.classList.remove("dismissed");
        }, 500);
        acceptButton.addEventListener("click", okWithCookies);
        manageButton.addEventListener("click", () => {
            const cookieBanner = document.getElementById("cookie-banner");
            cookieBanner.classList.add("dismissed");
        });
    } else okWithCookies();
};

export function handleCookies() {
    const addCookieBanner = () => {
        const bannerStyle = document.createElement("style");
        bannerStyle.innerHTML =
            "#cookie-banner{background:#fff;opacity:.95;font-size:16px;line-height:inherit;font-weight:400;width:100%;position:fixed;z-index:50;bottom:0;left:0;padding:1em;display:flex;align-self:center;justify-content:flex-start;transition:.5s all cubic-bezier(.3,0,.2,1);color:#000}#cookie-banner p{max-width:65%}#cookie-banner .cookies.button{padding:8px 24px;font-size:18px}@media (max-width:700px){#cookie-banner{justify-content:center}}#cookie-banner.dismissed{bottom:-.5em;opacity:0;pointer-events:none}#cookie-banner>div{justify-content:space-between;max-width:960px;margin:0 auto;padding:1em;display:flex;align-items:center}.pn-link{color:#000!important;font-size:16px;font-weight:700;text-decoration:underline}@media screen and (max-width:1024px){#cookie-banner>div{flex-direction:column}#cookie-banner span{text-align:center}#cookie-banner .pn-link,#cookie-banner span{max-width:100%;margin-bottom:8px}}#cookie-banner h3, #cookie-banner #manage-btn{display:none;}";
        const bannerCookie = document.createElement("div");
        bannerCookie.innerHTML = `<div id="cookie-banner"><div class="cookie-modal"><h3>This website uses cookies</h3><p>We use cookies and other technologies to make our website work. We also use third-party analytics and profiling cookies. By clicking ‘I Agree’, you agree to the usage of cookies as described in the <a href="https://30dayfitness.app/privacy-policy/" class="pn-link">privacy notice</a>.</p><button class="button cookies" id="accept-btn">I Agree</button><button id="manage-btn">Manage Cookies</button></div></div>`;

        // Create and append CSS for the banner
        document.head.appendChild(bannerStyle);

        // Create and append banner div
        document.body.appendChild(bannerCookie);
    };

    addCookieBanner();

    const cookieBanner = document.getElementById("cookie-banner");
    const acceptButton = document.getElementById("accept-btn");
    const manageButton = document.getElementById("manage-btn");
    checkStatus(cookieBanner, acceptButton, manageButton);
}

// ADJUST SDK LINK INTEGRATION

async function setAdjustID() {
    const uuid = await getUserUUID();
    const adjustParam = `&adjust_web_id=${uuid}`;
    const links = document.querySelectorAll(".adjust-link");

    console.log(adjustParam);

    links.forEach((el) => {
        el.href += adjustParam.toString();
    });
}

let userUUID = null;
let userUUIDPromise = null;

async function getUserUUID() {
    if (userUUID) return userUUID;

    if (!userUUIDPromise) {
        userUUIDPromise = new Promise((resolve) => {
            // Poll every 100ms
            const interval = setInterval(async () => {
                // Retrieve UUID
                const uuid = isSupported()
                    ? await readFromIndexedDB()
                    : readFromLocalStorage();
                // Stop search and resolve promise
                if (uuid) {
                    clearInterval(interval);
                    resolve(uuid);
                }
            }, 100);
        });
    }

    userUUID = await userUUIDPromise;
    return userUUID;
}

function isSupported() {
    // Copied from: https://github.com/adjust/web_sdk/blob/4390d1258957a9a12f0bbec0251a7977a4b56654/src/sdk/storage/indexeddb.js#L19
    const indexedDB = getIndexedDB();
    const iOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    return !!indexedDB && !iOS;
}

async function readFromIndexedDB() {
    const indexedDB = getIndexedDB();
    // Check for database existence
    const databases = await indexedDB.databases();
    if (!databases.some((db) => db.name == "adjust-sdk")) return;

    // Open the DB
    const openEvent = await new Promise(
        (resolve) => (indexedDB.open("adjust-sdk").onsuccess = resolve)
    );
    const db = openEvent.target.result;

    // Check for datastore existence
    const objectStores = db.objectStoreNames;
    if (!objectStores.contains("as")) return;

    // Retrieve the uuid
    const getEvent = await new Promise(
        (resolve) =>
            (db
                .transaction("as")
                .objectStore("as")
                .getAll().onsuccess = resolve)
    );
    const firstRecord = getEvent.target.result[0];

    // Could be too early
    if (!firstRecord) return;

    // UUID is ready
    return firstRecord.u;
}

function readFromLocalStorage() {
    // Read from localStorage
    const data = JSON.parse(localStorage.getItem("adjust-sdk.as"));

    // Could be too early
    if (!data) return;

    // UUID is ready
    return data[0].u;
}

function getIndexedDB() {
    return (
        window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB
    );
}
